import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import imgLogo from "../assets/image/logo-main-black.png";
import imgError from "../assets/image/svg/404.svg";
import axiosConfig from '../axiosConfig';

const CancellationRefund = () => {
	return (
    <>
      
   <PageWrapper>
      <div class="jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12">
	  <div class="container">
	  <div class="row justify-content-center">
	  <div class="col-xl-12 col-lg-11 mb-8 px-xxl-15 px-xl-0">
	  <div class="bg-white rounded-4 border border-mercury shadow-9">
	  <div class="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-8 border-bottom border-width-1 border-default-color light-mode-texts">
	  <div class="row">
	  <div class="col-md-6">
	  <div class="media align-items-center">
	  <div>
	 <h2 class="font-size-9 mb-6">Cancellation Refund</h2>
	  </div></div></div></div></div>
	  <div class="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 light-mode-texts">
	  <div class="row">
	  <div class="col-xl-11 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20">
	  <div class="">
	  <p class="font-size-4 text-black-2 mb-7">
	  Our cancellation and refund policy ensures a smooth process for our customers. Cancellations made within the specified period are eligible for a full or partial refund, depending on the service or product. Refunds will be processed through the original payment method within a given timeframe. Certain services or products may have non-refundable conditions, which will be clearly mentioned at the time of purchase. For any refund requests, please contact our support team with the necessary details. We strive to handle all cancellations and refunds promptly and fairly.
	  </p>
	
	  
	  
	  </div>
	 </div></div></div></div></div></div></div></div>
		
      </PageWrapper>
    </>
  );
};
export default CancellationRefund;
